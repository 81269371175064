import { useRef } from "react";
import Cta from "./components/cta";
import Header from "./components/header";
import Intro from "./components/intro";
import Projects from "./components/projects";
import Skills from "./components/skills";
import Footer from "./components/footer";

function App() {
  return (
    <div>
      <Header/>
      <Intro />
      <Skills/>
      <Projects/>
      <Cta />
      <Footer/>
    </div>
  );
}

export default App;
