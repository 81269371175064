export default function Intro() {
  function onClick(e) {
    e.preventDefault();
    window.scroll({
      top: document.documentElement.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className="intro-section">
      <svg
        className="rings-pattern-intro"
        xmlns="http://www.w3.org/2000/svg"
        width="530"
        height="129"
      >
        <g fill="none" fill-rule="evenodd" stroke="#FFF" opacity=".25">
          <ellipse cx="265" cy="40" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="52" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="65" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="77" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="89" rx="264.5" ry="39.5" />
        </g>
      </svg>
      <div className="intro-text-container">
        <div className="heading-primary heading-intro">
          Nice to meet you! I'm{" "}
          <span className="name-highlight">Taavish Thaman</span>.
        </div>
        <div className="intro-description">
          I’m a Software Engineer passionate about building web and mobile
          applications that drive businesses.
        </div>
        <a
          className="contact-link margin-top"
          href="#"
          onClick={(e) => onClick(e)}
        >
          CONTACT ME
        </a>
      </div>
      <svg
        className="circle-pattern-intro"
        xmlns="http://www.w3.org/2000/svg"
        width="129"
        height="129"
      >
        <circle
          cx="830.5"
          cy="585.5"
          r="64"
          fill="none"
          stroke="#FFF"
          transform="translate(-766 -521)"
        />
      </svg>
      <div className="developer-img"></div>
      <hr className="rule"></hr>
    </div>
  );
}
