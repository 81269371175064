const projectsData = [
  {
    id: "0",
    src: "assets/invoice_large.webp",
    title: "Invoice Application",
    tech: ["MERN", "Typescript", "Authentication"],
    code: true,
    project_link: "https://reactive-invoice-app.netlify.app/",
    code_link: "https://github.com/taavishthaman/invoice-app",
  },
  {
    id: "1",
    src: "assets/kanban-large.webp",
    title: "Kanban Task Management",
    tech: ["React", "Redux", "Node", "MongoDB"],
    code: true,
    project_link: "https://kanban-board-manager.netlify.app/",
    code_link: "https://github.com/taavishthaman/kanban-task-management",
  },
  {
    id: "2",
    src: "assets/pokedex-large.webp",
    title: "Pokédex",
    tech: ["Html", "Css", "React"],
    code: true,
    project_link: "https://reactive-pokedex.netlify.app/",
    code_link: "https://github.com/taavishthaman/pokedex",
  },
  {
    id: "3",
    src: "assets/game-of-life-large.webp",
    title: "Conway's Game of Life",
    tech: ["Html", "Css", "React", "Typescript"],
    code: true,
    project_link: "https://reactive-conways-game-of-life.netlify.app/",
    code_link: "https://github.com/taavishthaman/conways-game-of-life",
  },
  {
    id: "4",
    src: "assets/todo-app-large.webp",
    title: "Draggable Todo App",
    tech: ["React", "Node", "Express", "MongoDB"],
    code: true,
    project_link: "https://reactive-draggable-todo-app.netlify.app/",
    code_link: "https://github.com/taavishthaman/draggable-todo-list",
  },
  {
    id: "5",
    src: "assets/pomodoro-large.webp",
    title: "Pomodoro",
    tech: ["HTML", "CSS", "React", "Redux"],
    code: true,
    project_link: "https://reactive-pomodoro.netlify.app/",
    code_link: "https://github.com/taavishthaman/pomodoro",
  },
  {
    id: "7",
    src: "assets/sorting-visualizer-large.webp",
    title: "Sorting Visualizer",
    tech: ["Html", "Css", "React", "Redux"],
    code: true,
    project_link: "https://reactive-sorting-visualizer.netlify.app/",
    code_link: "https://github.com/taavishthaman/sorting-visualizer",
  },
  {
    id: "7",
    src: "assets/ip-address-tracker-large.webp",
    title: "IP Address Tracker",
    tech: ["Html", "Css", "React"],
    code: true,
    project_link: "https://reactiplocationtracker.netlify.app",
    code_link: "https://github.com/taavishthaman/ip-address-location-tracker",
  },
  {
    id: "8",
    src: "assets/atlas-large.webp",
    title: "World Atlas",
    tech: ["Html", "Css", "React", "Redux"],
    code: true,
    project_link: "https://react-atlas.netlify.app/",
    code_link: "https://github.com/taavishthaman/where-in-the-world",
  },
];

export default function Projects() {
  function onClick(e) {
    e.preventDefault();
    window.scroll({
      top: document.documentElement.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className="projects-section">
      <div className="projects-header">
        <div className="heading-primary">Projects</div>
        <a className="contact-link" href="" onClick={(e) => onClick(e)}>
          CONTACT ME
        </a>
      </div>
      <div className="projects-grid">
        {projectsData.map((project) => (
          <Project
            src={project.src}
            title={project.title}
            tech={project.tech}
            code={project.code}
            projectLink={project.project_link}
            codeLink={project.code_link}
          />
        ))}
      </div>
    </div>
  );
}

function Project({ src, title, tech, code, projectLink, codeLink }) {
  return (
    <div className="project">
      <img className="project-img" src={src} alt={title}></img>
      <div className="overlay"></div>
      <div className="heading-tertiary">{title}</div>
      <div className="tech-list">
        {tech.map((t) => (
          <p className="tech-name">{t}</p>
        ))}
      </div>
      <div className="view">
        <a
          className="contact-link"
          href={projectLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          VIEW PROJECT
        </a>
        {code && (
          <a
            className="contact-link"
            href={codeLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            VIEW CODE
          </a>
        )}
      </div>
    </div>
  );
}
