import emailjs from "emailjs-com";
import { useRef, useReducer } from "react";

const SERVICE_ID = "service_mvkvehl";
const TEMPLATE_ID = "template_4mcoybl";
const PUBLIC_KEY = "9mIeEyHkbVKaxl7ab";

const initialState = {
  from_name: "",
  from_email: "",
  message: "",
  name_error: false,
  email_error: false,
  message_error: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "change/name":
      return {
        ...state,
        from_name: action.payload,
        name_error: false,
      };
    case "change/email":
      return {
        ...state,
        from_email: action.payload,
        email_error: false,
      };
    case "change/message":
      return {
        ...state,
        message: action.payload,
        message_error: false,
      };
    case "no/name":
      return {
        ...state,
        name_error: true,
      };
    case "no/email":
      return {
        ...state,
        email_error: true,
      };

    case "no/message":
      return {
        ...state,
        message_error: true,
      };
    case "reset":
      return {
        ...initialState,
      };
    default:
      throw new Error("Invalid Action!");
  }
}

export default function Cta() {
  const [
    { from_name, from_email, message, name_error, email_error, message_error },
    dispatch,
  ] = useReducer(reducer, initialState);
  const formRef = useRef();

  function sendMessage(e) {
    e.preventDefault();
    if (!from_name) {
      dispatch({ type: "no/name" });
    }
    if (!from_email) {
      dispatch({ type: "no/email" });
    }
    if (!message) {
      dispatch({ type: "no/message" });
    }

    if (!from_name || !from_email || !message) {
      return;
    }

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, PUBLIC_KEY)
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: "reset" });
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      });
  }

  function changeEmail(e) {
    dispatch({ type: "change/email", payload: e.target.value });
  }

  function changeName(e) {
    dispatch({ type: "change/name", payload: e.target.value });
  }

  function changeMessage(e) {
    dispatch({ type: "change/message", payload: e.target.value });
  }

  return (
    <div className="cta-section">
      <div className="cta-grid">
        <div className="cta-contact">
          <div className="heading-primary cta-heading">Contact</div>
          <div className="cta-description">
            I would love to hear about your project and how I could help. Please
            fill in the form, and I’ll get back to you as soon as possible.
          </div>
        </div>
        <div className="cta-form">
          <form ref={formRef}>
            <input
              className={`text-field ${name_error ? "field-error" : ""}`}
              type="text"
              placeholder="NAME"
              name="from_name"
              onChange={(e) => changeName(e)}
              value={from_name}
              required
            ></input>
            {name_error && <p className="error-text">Name cannot be empty!</p>}
            <input
              className={`text-field margin-top-sm ${
                email_error ? "field-error" : ""
              }`}
              type="email"
              name="from_email"
              onChange={(e) => changeEmail(e)}
              value={from_email}
              placeholder="EMAIL"
              required
            ></input>
            {email_error && (
              <p className="error-text">Email cannot be empty!</p>
            )}
            <textarea
              className={`text-area margin-top-sm ${
                message_error ? "field-error" : ""
              }`}
              type="text"
              value={message}
              placeholder="MESSAGE"
              onChange={(e) => changeMessage(e)}
              name="message"
              required
            ></textarea>
            {message_error && (
              <p className="error-text">Message cannot be empty!</p>
            )}
            <button
              className="form-btn"
              type="submit"
              onClick={(e) => sendMessage(e)}
            >
              <a className="contact-link margin-top-sm" href="#">
                SEND MESSAGE
              </a>
            </button>
          </form>
        </div>
      </div>
      <svg
        className="rings-pattern-cta"
        xmlns="http://www.w3.org/2000/svg"
        width="530"
        height="129"
      >
        <g fill="none" fill-rule="evenodd" stroke="#FFF" opacity=".25">
          <ellipse cx="265" cy="40" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="52" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="65" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="77" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="89" rx="264.5" ry="39.5" />
        </g>
      </svg>
      <hr className="rule-cta"></hr>
    </div>
  );
}
