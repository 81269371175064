const skillList = [
  { id: 0, title: "HTML/CSS", exp: 4 },
  { id: 1, title: "Javascript", exp: 4 },
  { id: 2, title: "React", exp: 3 },
  { id: 3, title: "Node", exp: 3 },
  { id: 4, title: "Typescript", exp: 2 },
  { id: 5, title: "Hybrid Apps", exp: 2 },
  { id: 6, title: "Algorithms", exp: 3 },
  { id: 7, title: "C++", exp: 2 },
  { id: 8, title: "Unity", exp: 2 },
];

export default function Skills() {
  return (
    <div className="skills-section">
      {skillList.map((skill) => (
        <Skill title={skill.title} experience={skill.exp} />
      ))}
      <svg
        className="rings-pattern-skill"
        xmlns="http://www.w3.org/2000/svg"
        width="530"
        height="129"
      >
        <g fill="none" fill-rule="evenodd" stroke="#FFF" opacity=".25">
          <ellipse cx="265" cy="40" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="52" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="65" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="77" rx="264.5" ry="39.5" />
          <ellipse cx="265" cy="89" rx="264.5" ry="39.5" />
        </g>
      </svg>
    </div>
  );
}

function Skill({ title, experience }) {
  return (
    <div className="skill">
      <div className="heading-secondary">{title}</div>
      <p className="skill-experience">{experience} Years Experience</p>
    </div>
  );
}
